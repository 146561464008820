


















import { Component, Vue, Inject } from "vue-property-decorator";
import AuthService from "@/services/auth-service";

@Component
export default class Login extends Vue {
  @Inject() AuthService!: AuthService;

  public currentUser? = "";

  public isLoggedIn = false;

  get username(): string | undefined {
    return this.currentUser;
  }

  public login() {
    const redirect = this.$route.query.redirect as string;
    this.AuthService.login(redirect);
  }

  public logout() {
    this.$router.push("/logout");
  }

  public async mounted() {
    const user = await this.AuthService.getUser();

    if (user) {
      this.currentUser = user.profile.name;
      this.isLoggedIn = !this.AuthService.IsExpired(user);
    }
  }
}
